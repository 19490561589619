import React from "react";
import SEO from "../components/seo";
import { Link } from "@reach/router";

const NotFoundPage = () => (
    <>
        <SEO title="404: Not found" />
        <section className={"mx-auto px-8 py-32"}>
            <div className={"mx-auto"} style={{ maxWidth: 800 }}>
                <h2 className={"text-3xl"}>Oops!</h2>
                <p style={{ maxWidth: 400 }}>
                    We can't seem to find the page you're looking for. Maybe you can visit our{" "}
                    <Link to="/" className={"text-gray-700 underline"}>
                        home page
                    </Link>{" "}
                    instead
                </p>
            </div>
        </section>
    </>
);

export default NotFoundPage;
